/* global ga */
// https://www.awoo.com.tw/blog/ecommerce-ga-tracking/
// https://stackoverflow.com/questions/38540015/enhanced-ecommerce-product-category-not-set-in-the-product-performance-card
// ga("create", "UA-65742661-9", "auto", "m")
// ga("m.require", "ec")
// ga("m.set", "currencyCode", "TWD")
// ga("m.ec:addProduct", { id: "717", name: "商品名稱 (1)", price: "100", brand: "品牌 (1)", category: "分類", quantity: 1} )
// ga("m.ec:addProduct", { id: "718", name: "商品名稱 (2)", price: "100", brand: "品牌 (2)", category: "分類", quantity: 1} )
// ga("m.ec:setAction", "purchase", {id: "16O00000011", revenue: "100", shipping: "10"})
// ga("m.send", "pageview")
// ga("m.send", "event", "Hello", "abc", "title")
import TrackerBase from './TrackerBase'
import url from 'url'
import get from 'lodash.get'

class TrackerGa extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
    this.trackers = []
  }

  init() {
    const { ga, currency } = this.page
    if (! ga) {
      return this
    }
    this._loadScript()
    ga.global && this._initGa('global', ga.global, currency)
    ga.user && this._initGa('user', ga.user, currency)
    this.enabled = Boolean(ga.global) || Boolean(ga.user)
    return this
  }

  addProducts({ cart, site, tracker }) {
    if (cart) {
      cart.items.forEach(item => {
        const row = {
          id: item.id,
          name: item.title,
          price: item.price,
          category: this.page.aliasName,
          quantity: item.qty
        }
        if (site) {
          row.brand = site.title
        }
        ga(`${tracker}.ec:addProduct`, row)
      })
    }
  }

  addImpressions({ tracker, site, sortedProductMap }) {
    this.page.products.forEach((product, index) => {
      const row = {
        id: product.id,
        name: product.title,
        category: this.page.aliasName,
        position: sortedProductMap ? sortedProductMap[product.id] : (index + 1)
      }
      if (site) {
        row.brand = site.title
      }
      ga(`${tracker}.ec:addImpression`, row)
    })
  }

  pageView({ cart, site, title, step }) {

    const isFirstStep = (step === 1)

    this._trackAll(tracker => {
      this.addProducts({ cart, site, tracker })
      if (isFirstStep) {
        ga(`${tracker}.ec:setAction`, 'checkout', { step: 1 })
      }
      const path = url.parse(location.href).path
      ga(`${tracker}.set`, 'page', path)
      ga(`${tracker}.send`, 'pageview')
      if (isFirstStep) {
        ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP1: 進入頁面', title, {
          nonInteraction: true
        })
      }
    })
  }

  viewContent({ title, site, cart, sortedProductMap }) {
    this._trackAll(tracker => {
      this.addImpressions({ tracker, site, sortedProductMap })
      ga(`${tracker}.ec:setAction`, 'detail')
      ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP2: 看完頁面', title)

      this.addProducts({ cart, site, tracker })
      ga(`${tracker}.ec:setAction`, 'checkout', { step: 2 })
      ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP2: 看完頁面', title)
    })
  }

  addToCart({ productId, title, price, qty, cart, site }) {
    this._trackAll(tracker => {
      ga(`${tracker}.ec:addProduct`, { id: productId, name: title, price, quantity: qty })
      ga(`${tracker}.ec:setAction`, 'add')
      ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP3: 加入購物車', title)

      this.addProducts({ cart, site, tracker })
      ga(`${tracker}.ec:addProduct`, { id: productId, name: title, price, quantity: qty })
      ga(`${tracker}.ec:setAction`, 'checkout', { step: 3 })
      ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP3: 加入購物車', title)
    })
  }

  checkout({ title, cart, site }) {
    this._trackAll(tracker => {
      this.addProducts({ cart, site, tracker })
      ga(`${tracker}.ec:setAction`, 'checkout', { step: 4 })
      ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP4: 開始結帳', title)
    })
  }

  purchase({ orderId, pageTitle, totalOrderAmount, cart, site }) {
    this._trackAll(tracker => {
      this.addProducts({ cart, site, tracker })
      ga(`${tracker}.ec:setAction`, 'purchase', { id: orderId, revenue: totalOrderAmount })
      ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP5: 結帳完成', pageTitle)

      this.addProducts({ cart, site, tracker })

      const paymentMethod = get(cart, 'info.paymentMethod')

      ga(`${tracker}.ec:setAction`, 'checkout', {
        step: 5,
        option: paymentMethod
      })
      ga(`${tracker}.send`, 'event', '銷售漏斗', 'STEP5: 結帳完成', pageTitle)
    })
  }

  _initGa(tracker, code, currency) {
    // 啟用 GA tracking Code + 電子商務
    ga('create', code, 'auto', tracker)
    ga(`${tracker}.set`, 'currencyCode', currency)
    ga(`${tracker}.require`, 'ec')
    this.trackers.push(tracker)
  }

  _trackAll(func) {
    this.trackers.forEach(tracker => func(tracker))
  }

  _loadScript() {
    // from https://developers.google.com/analytics/devguides/collection/analyticsjs/
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  }
}

export default TrackerGa
