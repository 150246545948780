/* global dataLayer, gtag */
// https://developers.google.com/gtagjs/devguide/routing
// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce
// https://support.google.com/google-ads/answer/6095821?hl=zh-Hant

import TrackerBase from './TrackerBase'

class TrackerGtag extends TrackerBase {

  constructor({ page }) {
    super()
    this.page = page
  }

  init() {
    const { gtagAds } = this.page
    if (! gtagAds) {
      return this
    }

    const [conversionId, conversionLabel] = gtagAds.split('/')
    this.conversionId = conversionId
    this.conversionLabel = conversionLabel

    this._loadScript(this.conversionId)
    this.enabled = true
    return this
  }

  pageView() {
  }

  viewContent() {
  }

  addToCart() {
  }

  checkout() {
  }

  purchase({ orderId, totalOrderAmount, currency, orderEmail, orderMobile }) {
    // Beta: Set up enhanced conversions manually with the global site tag
    // https://support.google.com/google-ads/answer/9888145
    window.enhanced_conversion_data = {}
    if (orderEmail) {
      window.enhanced_conversion_data.email = orderEmail
    }
    if (orderMobile) {
      window.enhanced_conversion_data.phone_number = orderMobile
    }
    gtag('event', 'conversion', {
      'send_to': `${this.conversionId}/${this.conversionLabel}`,
      'value': totalOrderAmount,
      'currency': currency,
      'transaction_id': orderId
    })
    delete window.enhanced_conversion_data
  }

  _loadScript(conversionId) {

    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${conversionId}`
    document.head.appendChild(script)

    window.gtag = function() {
      dataLayer.push(arguments)
    }
    window.dataLayer = window.dataLayer || []
    gtag('js', new Date())
    gtag('config', conversionId, { allow_enhanced_conversions: true })
  }
}

export default TrackerGtag
