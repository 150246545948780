/* global dataLayer */
// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag#measure_purchases
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase

class Ga4 {

  static purchase(page, order) {
    const { currency } = page
    const params = {
      transaction_id: order.globalOrderId,
      value: order.totalOrderAmount,
      shipping: order.shippingFee,
      currency,
      items: order.items.map(item => {
        return {
          item_id: item.id,
          item_name: item.title,
          price: item.price,
          item_category: page.aliasName,
          quantity: item.qty
        }
      })
    }
    const gtag = function() {
      dataLayer.push(arguments)
    }
    gtag('event', 'purchase', params)
  }
}

export default Ga4
